import React, { useState,useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { Link } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { mainListItems } from './ListItem';
import Sidebar from './Include';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Button from '@mui/material/Button';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import ClearIcon from '@mui/icons-material/Clear';
import { TransformWrapper, TransformComponent,useControls } from "react-zoom-pan-pinch";


function Copyright() {
  return (
   
    <Typography variant='body2' noWrap sx={{ pt: 4,marginBottom:'10px' }}    align="center" >
      {'Copyright © '} Designed by
      <span  noWrap  style={{ fontWeight: 'bolder'}}>   Geo Infotech</span>
   
    {' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  
  );
}




// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();
 
export default function Dashboard() {
  const [open, setOpen] = React.useState(true);
  const [loading, setLoading] = useState(false)
  const [value, setValue] = React.useState('one');
  const [zoomedImageSrc, setZoomedImageSrc] = useState([]);
  const [caption, setCaption] = useState('');
  const [sidebarItems, setSidebarItems] = useState([]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const toggleDrawer = () => {
    setOpen(!open);
  };
// Inside Dashboard component
const [selectedItem, setSelectedItem] = React.useState([]);
// const [selectedId, setSelectedId] = React.useState('');
const handleItemClick = (itemName) => {
  setSelectedItem(itemName);
  setZoomedImageSrc([]);
  fetchData(itemName)

};
const fetchData = async (itemName) => {
  setLoading(true);
  try {
   
    const requestData = {
      method: 'district_map',
      district: itemName,
     
    };

    const response = await fetch('https://odisha-cultural-atlas.examot.com/api/v1.php', {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    });
    const data = await response.json();

    if (data.code == '200') {
        setLoading(false);
      setCaption(data.data.caption);
      setZoomedImageSrc(data.data.picture);
      setSidebarItems(data.data.sidebar);
      if(selectedItem==''){
        if (data.data.sidebar.length > 0) {
       setSelectedItem(data.data.sidebar[0].slug);
        }
     }
    } else {
      // Handle other status codes or error scenarios
    }
  } catch (error) {
    console.error('Error fetching data:', error);
    // Handle error scenarios
  }
};

useEffect(() => {
  
  fetchData();
}, []); 
const Controls = () => {
  const { zoomIn, zoomOut, resetTransform } = useControls();
  return (
    <>
<IconButton onClick={() => zoomIn()}>
        <ZoomInIcon  sx={{color:'blue'}}/>
      </IconButton>
      <IconButton  onClick={() => zoomOut()}>
        <ZoomOutIcon  sx={{color:'blue'}} />
      </IconButton>
      <IconButton onClick={() => resetTransform()}>
        <ClearIcon  sx={{color:'blue'}} />
      </IconButton>
      
    </>
  );
};
  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex', minHeight:'100vh',overflowX:'hidden' }}>
        <CssBaseline />
        <Sidebar   selectedItem={selectedItem} handleItemClick={handleItemClick}/>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
           
          }}
        >
          <Toolbar />
          {loading && (
            <div
              style={{
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                background: 'rgba(255, 255, 255, 0.8)',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                zIndex: 9999,
              }}
            >
              <CircularProgress color="primary" />
            </div>
          )}
          <Container maxWidth="lg" sx={{ mt: 6, }}>
            <Grid container spacing={3}>
              {/* Chart */}
              <Grid item xs={12} md={12} lg={12}>
              <Paper
        sx={{
        
          height: 'auto',
        }}
      >
      
      <TransformWrapper
                defaultScale={1}
                defaultPositionX={200}
                defaultPositionY={100}
              >
                {({ zoomIn, zoomOut, resetTransform, positionX, positionY, ...rest }) => (
                  <React.Fragment>
                    <TransformComponent>
                      {/* Place your zoomable content here */}
                      <img src={zoomedImageSrc} alt="" srcset="" style={{width:'100%'}} />
                    </TransformComponent>
                    <Box sx={{display:"flex", justifyContent:"flex-end"}}>
                    <Controls />
                    </Box>

                  </React.Fragment>
                  
                )}
              </TransformWrapper>
      </Paper>
              </Grid>
              {/* Recent Deposits */}
            
              {/* Recent Orders */}
              <Grid item xs={12}>
              <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
  <Typography
    variant="body2" sx={{ textAlign: 'justify' }}
    dangerouslySetInnerHTML={{ __html: caption }}
  />
  <Typography
    component={Link}
    to={`/district-cultural-details?selectedItem=${selectedItem}`}
    sx={{ color: 'blue', cursor: 'pointer', marginTop: '4px' }}
  >
    Read More
  </Typography>
</Paper>


              </Grid>
            </Grid>
            <Copyright />
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
} 