import React, { useState,useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import styles from "./Category.module.css";
import { styled, createTheme, ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import MuiDrawer from "@mui/material/Drawer";
import { useNavigate, useLocation } from 'react-router-dom'; // Import useNavigate
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";
import Sidebar from "./Include";
import { mainListItems } from "./ListItem";
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import { Box, CircularProgress } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Button from '@mui/material/Button';


const VerticalLine = styled("div")({
  borderLeft: "1px solid #ccc",
  height: "100%",
  margin: "0 16px",
});
function Copyright() {
  return (
    <Typography variant='body2' noWrap  sx={{pt:1,pb:1}}  align="center" >
    {'Copyright © '} Designed by
    <span  noWrap  style={{ fontWeight: 'bolder'}}>   Geo Infotech</span>
 
  {' '}
    {new Date().getFullYear()}
    {'.'}
  </Typography>

  );
}
export default function ZigzagPage() {
  const navigate = useNavigate();
 // const selectedItem = new URLSearchParams(location.search).get('selectedItem');

  const location = useLocation();
  const [open, setOpen] = React.useState(true);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [value, setValue] = React.useState("one");
  const params = new URLSearchParams(location.search);
 
  const querySelectedItem = params.get('selectedItem');
  const [selectedItem, setSelectedItem] = useState(querySelectedItem);

  useEffect(() => {
    if (querySelectedItem) {
    console.log(querySelectedItem)
      setSelectedItem(querySelectedItem);
    }
  }, [querySelectedItem]);
  const theme = useTheme();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [loading, setLoading] = useState(false);

  const [categories, setCategories] = useState([]);
  const [zigzagData, setzigzagData] = useState([]);
  // Simulating an API call
 

  const handleCategoryClick = (categoryName) => {
   
    setSelectedCategory(categoryName);
    fetchDataa(categoryName)
  };

  // Initial data fetching

  const handlebacknavigation = () => {
   
    navigate('/district-cultural');
  };
  const HorizontalLine = styled('hr')({
  borderTop: '1px solid #ccc',
  width: '100%',
  margin: '10px 0',
});

const handleItemClick = (itemName) => {
  setSelectedItem(itemName);
 
 
  fetchData(itemName)
};
const fetchData = async (itemName) => {
  setLoading(true);
  try {
  
    const requestData = {
      method: 'district_map_details',
      district:itemName,
     // category: itemid,
     
    };

    const response = await fetch('https://odisha-cultural-atlas.examot.com/api/v1.php', {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    });
    const data = await response.json();

    if (data.code == '200') {
        setLoading(false);
      // setCaption(data.data.caption);
      setCategories(data.data.category);
      setzigzagData(data.data.places)
      // console.log(data.data.picture)
    } else {
      // Handle other status codes or error scenarios
    }
  } catch (error) {
    console.error('Error fetching data:', error);
    // Handle error scenarios
  }
};



useEffect(() => {
  //  console.log(selectedItem)
   fetchData(selectedItem)
   
  }, [selectedItem]);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [caption, setcaption] = useState('');
  const handleOpenModal = (imgcaption,imageSrc) => {
    setSelectedImage(imageSrc);
    setcaption(imgcaption)
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
    setcaption('')
    setModalOpen(false);
  };


  const fetchDataa = async (categoryName) => {
    setLoading(true);
    try {
    
      const requestData = {
        method: 'district_map_details',
        district:selectedItem,
       category: categoryName,
       
      };
  
      const response = await fetch('https://odisha-cultural-atlas.examot.com/api/v1.php', {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });
      const data = await response.json();
  
      if (data.code == '200') {
          setLoading(false);
        // setCaption(data.data.caption);
        setCategories(data.data.category);
        setzigzagData(data.data.places)
        // console.log(data.data.picture)
      } else {
        // Handle other status codes or error scenarios
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      // Handle error scenarios
    }
  };
  return (
    <ThemeProvider theme={createTheme()}>
        <Box sx={{ display: 'flex', overflow: 'hidden',minHeight:'100vh'}}> 
      <CssBaseline />
      <Sidebar  selectedItem={selectedItem} handleItemClick={handleItemClick}/>
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[900],
          flexGrow: 1,
          height: "100vh",
          height: 'auto',
         
        }}
      >
        <Toolbar />
        <Container maxWidth="lg" sx={{ mt: 6, mb: 8}}>
        <Box sx={{ display: 'flex' }}>
            <ArrowBackIcon sx={{ color: '#005AC1', fontSize: 20, cursor: 'pointer',marginTop:'6px' }} onClick={handlebacknavigation} />
            <Typography sx={{ fontSize: '14px', color: '#005AC1', fontWeight: '500', cursor: 'pointer' ,marginTop:'6px'}} onClick={handlebacknavigation}>Go back</Typography>
     
          </Box>
        <div className={styles.categories}>
      <Box
        style={{
          display: 'flex',
          overflowX: 'auto',
          overflow: 'hidden',
          marginBottom: '30px',
          marginTop: '10px',
        }}
      >
        {loading ? (
          <CircularProgress sx={{ width: '100%', borderRadius: '28px' }} />
        ) : (
          
          <div className={styles.categories}>
          {/* {categories.length > 0 && (
            <Box
              className={styles.arrowIcon}
              onClick={() => {
                const categoryContainer = document.querySelector(`.${styles.categories} > div`);
                categoryContainer.scrollLeft -= 100;
              }}
            >
              <ArrowLeftIcon />
            </Box>
          )} */}
          {categories.map((category, index) => (
            <Box
              key={index}
              className={`${styles.inputChip} ${selectedCategory === category ? styles.selectedCategory : ''}`}
              onClick={() => handleCategoryClick(category)}
            >
              {category}
            </Box>
          ))}
          {/* {categories.length > 0 && (
            <Box
              className={styles.arrowIcon}
              onClick={() => {
                const categoryContainer = document.querySelector(`.${styles.categories} > div`);
                categoryContainer.scrollLeft += 100;
              }}
            >
              <ArrowRightIcon />
            </Box>
          )} */}
        </div>
        )}
      </Box>
    </div>
          {/* <Grid container spacing={2}>
            {zigzagData.map((item, index) => (
              <Grid item xs={12} sm={8} md={6} key={index}>
                <Box>
                  {item.type === "image" && (
                    <img
                      src={item.src}
                      alt={item.alt}
                      style={{ width: "100px",  height: "100px" }} // Set max width for the image
                    />
                  )}
                  {item.type === "text" && (
                    <div style={{ flex: 1 }}>
                      <Typography variant="body2">{item.content}</Typography>
                    </div>
                  )}
                </Box>
              </Grid>
            ))}
          </Grid> */}
          
      <Box >
      {zigzagData.map((item, index,array) => (
  <React.Fragment key={index}>
  
  <Box style={{  alignItems: "flex-start" }}>
  <img
    src={item.picture}
    alt={item.alt}
    style={{
      width: "30%",
      //height: "100px",
   
      float:'left',
      marginRight: "16px",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Add a shadow
      borderRadius: "8px", // Add border radius
      cursor:'pointer',
    }}
    onClick={() => handleOpenModal(item.caption, item.picture)}
  />
 
</Box>
<Box style={{ flex: 1 }}>
    <Typography variant="h5" sx={{ fontWeight: 'bold' }}>{item.caption}</Typography>
    <Typography variant="body2" sx={{ textAlign: 'justify' }}>{item.description}</Typography>
  </Box>
  
  <div style={{ clear: 'both' }} />
 
      {index !== array.length - 1 && (
                    <HorizontalLine  />
                  )}
  </React.Fragment>
))}
 

    </Box>
 
    <Paper sx={{ position: 'fixed', bottom: 0, left: 0, alignItems:'center', backgroundColor:'white', width: '100%',zIndex:'99999' }}>

    <Container >
 <Copyright  />
 </Container>
</Paper>
        </Container>
      </Box>
    </Box>
    <Dialog open={modalOpen} onClose={handleCloseModal}>
        <DialogTitle>{caption}</DialogTitle>
        <DialogContent>
          {selectedImage && (
            <img
              src={selectedImage}
              alt="Selected Modal"
              style={{ maxWidth: "100%" }}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal}>Close</Button>
        </DialogActions>
      </Dialog>
  </ThemeProvider>
  );
}
